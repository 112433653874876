import flatpickr from "flatpickr";
const initFlatpickr = () => {
  flatpickr("#datepicker", {
    defaultDate: "18/01/1993",
    dateFormat: "d/m/Y",
  });
}

export { initFlatpickr };



// import flatpickr from 'flatpickr'

// const initFlatPickr = () => {
//   flatpickr("#range_start", {
//     inline: true,
//     plugins: [new rangePlugin({ input: "#range_end"})]
//     plugins: [new rangePlugin({ input: "#range_end"})]
//   });
// }

// export { initFlatPickr };
