import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
  }

  update(event) {
    const statusToUpdateId = event.target.dataset.id
    // find status p
    const statusToUpdateElement = document.querySelector(`#status-${statusToUpdateId}`)
    // change it to green with text 'acceptée'
    statusToUpdateElement.classList.remove('btn_pastel_orange')
    statusToUpdateElement.classList.add('btn_pastel_green')
    statusToUpdateElement.innerHTML= "<strong>Acceptée</strong>"
    // disable button - which is event target
    event.target.disabled = true
  }
}
